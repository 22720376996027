import React, { useContext } from "react"
import {Link, graphql, navigate } from "gatsby"
import { homePagePaths, translate } from '../translate'
import Layout from "../components/layout"
import Seo from "../components/seo"
import Header from "../components/header"
import Footer from "../components/footer"
import Title from "../components/title"
import Section from "../components/section"
import { LanguageContext } from "../context"
import Banner from "../components/banner"
import SectionContent from "../components/section/section-full-with-image"
import Utils from "../utils"

const PageMissionandValues = ({ data, location, pageContext }) => {
    const post = data.nodePageGoel
    const nodeMissionAndValues = data.nodeMissionAndValuesGoel
    const { t } = useContext(LanguageContext)
    const translationPaths = {
     en: `/en${post?.path?.alias}`,
     es: `/es${post?.path?.alias}`,
     ca: `${post?.path?.alias}`
    }

    return(
      <React.Fragment>
      <Layout
        translationPaths={translationPaths}
        location={location}
        langcode={post?.langcode}
        className="page-beliefs-and-values"
        >
        <Seo
            title={post?.title}
            lang={post?.langcode}
            description={post?.body?.summary}
            image={post?.relationships?.field_image?.image_style_uri?._653_432}
          />
        <Header
          lang={pageContext?.langcode}
          location={location}
         />
         <Banner
           post={post}
           height={"408px"}
           backgroundColor="green"
         />
         <Section
          key={1}
          className="section-our-mission background-white p-t-80 small-p-t-24"
          id="section-our-mission"
          title={nodeMissionAndValues?.field_mission_title}
          //subtitle={nodeMissionAndValues?.field_mission_subtitle}
          headingClassName="H2-Medium-C---orange medium-p-b-0 small-p-b-0 m-b-32 medium-m-b-16 small-m-b-16 p-b-32"
          subHeadingClassName="H5-Medium-C---black max-877-center-content p-b-88 medium-p-l-r-24"
          t={t}
          >
            <SectionContent
              body={nodeMissionAndValues?.field_mission_body?.value}
              image={nodeMissionAndValues?.relationships?.field_mission_image?.image_style_uri?._653_432}
              imagePos="left"
              color="orange"
            />
          </Section>

          <Section
           key={2}
           className="section-our-vision background-beig p-t-80 p-b-80 small-p-t-24 medium-p-t-56 small-p-b-40"
           id="section-our-vision"
           title={nodeMissionAndValues?.field_vision_title}
           //subtitle={nodeMissionAndValues?.field_mission_subtitle}
           headingClassName="H2-Medium-C---green-dark medium-p-b-0 small-p-b-0 m-b-32 medium-m-b-16"
           subHeadingClassName="H5-Medium-C---black max-877-center-content p-b-88 medium-p-l-r-24"
           t={t}
           >
           <SectionContent
             body={nodeMissionAndValues?.field_vision_body?.value}
             image={nodeMissionAndValues?.relationships?.field_vision_image?.image_style_uri?._653_432}
             imagePos="center"
             color="green-dark"
             className="small-p-l-r-16 section-desc"
           />
           </Section>

           <Section
            key={3}
            className="section-our-values background-white p-t-80 small-p-t-24"
            id="section-our-values"
            title={nodeMissionAndValues?.field_values_title}
            subtitle={nodeMissionAndValues?.field_values_subtitle}
            headingClassName="H2-Medium-C---orange medium-p-b-0 small-p-b-0 m-b-32 medium-m-b-16 small-m-b-16"
            subHeadingClassName="H5-Medium-C---black max-877-center-content p-b-88 medium-p-l-r-24"
            t={t}
            >
              <SectionContent
                body={nodeMissionAndValues?.field_values_body?.value}
                image={nodeMissionAndValues?.relationships?.field_values_photo?.image_style_uri?._653_432}
                imagePos="left"
                color="orange"
              />
            </Section>

          {/*
           <Section
            key={666}
            className="section-as-church background-white p-t-80 small-p-t-24 medium-p-t-56"
            id="section-as-church"
            title={`Com a església, nosaltres…`}
            //subtitle={nodeMissionAndValues?.field_mission_subtitle}
            headingClassName="H2-Medium-C---orange medium-p-b-0 small-p-b-0 m-b-56 small-m-b-16"
            subHeadingClassName="H5-Medium-C---black max-877-center-content p-b-88 medium-p-l-r-24 medium-p-b-74 small-p-l-r-16 small-p-b-24"
            t={t}
            >
              <SectionContent
                image={nodeMissionAndValues?.relationships?.field_vision_image?.image_style_uri?._653_432}
                imagePos="center"
                color="green-dark"
                isArray
                content={nodeMissionAndValues?.field_as_a_church_we}
              />
            </Section>
            */}

            {/*
            <Section
             key={4}
             className="section-next-steps background-white small-p-t-24 heading-green-dark"
             id="section-next-steps"
             title={nodeMissionAndValues?.field_nextsteps_title}
             headingClassName="H2-Medium-C---green-dark medium-p-b-0 small-p-b-0 m-b-88 medium-m-b-74 small-m-b-13"
             //subHeadingClassName="H5-Medium-C---green-dark p-b-88"
             t={t}
             >
               <SectionContent
                 image={nodeMissionAndValues?.relationships?.field_nextsteps_image?.image_style_uri?._653_432}
                 imagePos="left"
                 color="green-dark"
                 body={nodeMissionAndValues?.field_nextsteps_body?.value}
               />
             </Section>
             */}

             {/*
             <Section
              key={5}
              className="section-baptism-comunion background-white p-b-80 small-p-t-24- small-p-b-0 heading-green-dark"
              id="section-baptism-comunion"
              title={nodeMissionAndValues?.field_batipsim_title}
              //subtitle={nodeMissionAndValues?.field_nextsteps_subtitle}
              headingClassName="H2-Medium-C---bordeaux medium-p-b-0 small-p-b-0 m-b-88 medium-m-b-74 small-m-b-13"
              //subHeadingClassName="H5-Medium-C---green-dark p-b-88"
              t={t}
              >
                <SectionContent
                  image={nodeMissionAndValues?.relationships?.field_batipsim_image?.image_style_uri?._653_432}
                  imagePos="right"
                  color="bordeaux"
                  body={nodeMissionAndValues?.field_batipsim_body?.value}
                  ctas={[
                    <Link
                      to={Utils.removeMainLanguage(nodeMissionAndValues?.field_batipsim_cta1?.url)}
                      title={nodeMissionAndValues?.field_batipsim_cta1?.title}
                      className="btn btn-outline btn-outline-bordeaux"
                      >
                      {nodeMissionAndValues?.field_batipsim_cta1?.title}
                    </Link>,
                    <Link
                      to={Utils.removeMainLanguage(nodeMissionAndValues?.field_batipsim_cta2?.url)}
                      title={nodeMissionAndValues?.field_batipsim_cta2?.title}
                      className="btn btn-outline btn-outline-bordeaux"
                      >
                      {nodeMissionAndValues?.field_batipsim_cta2?.title}
                      </Link>
                  ]}
                />
              </Section>
              */}

             {/*
              <Section
               key={6}
               className="section-basic-beliefs background-white p-b-80- small-p-t-24- heading-green-dark medium-p-b-0 small-p-b-0"
               id="section-basic-beliefs"
               title={nodeMissionAndValues?.field_basics_beliefs_title}
               //subtitle={nodeMissionAndValues?.field_nextsteps_subtitle}
               headingClassName="H2-Medium-C---green-dark medium-p-b-0 small-p-b-0 m-b-88 medium-m-b-74 small-m-b-13"
               //subHeadingClassName="H5-Medium-C---green-dark p-b-88"
               t={t}
               >
                 <SectionContent
                   image={nodeMissionAndValues?.relationships?.field_basics_beliefs_image?.image_style_uri?._653_432}
                   imagePos="left"
                   color="green-dark"
                   body={nodeMissionAndValues?.field_basics_beliefs_body?.value}
                   ctas={[
                     <Link
                       to={Utils.removeMainLanguage(nodeMissionAndValues?.field_basics_beliefs_cta?.url)}
                       title={nodeMissionAndValues?.field_basics_beliefs_cta?.title}
                       className="btn btn-outline btn-outline-green-dark"
                       >
                       {nodeMissionAndValues?.field_basics_beliefs_cta?.title}
                     </Link>
                   ]}
                 />
               </Section>
               */}


          <Footer
            lang={pageContext?.langcode}
            location={location}
           />
        </Layout>
      </React.Fragment>
  )
}

export const pageQuery = graphql`
query PageMissionandValues ($drupal_internal__nid: Int, $langcode: String!) {
    nodePageGoel (
        drupal_internal__nid: {eq: $drupal_internal__nid},
        langcode:{eq: $langcode}
    )
    {
        title
        body {
            value
        }
        relationships {
            field_image{
                image_style_uri{
                  wide
                  _653_432
                }
            }
        }
    }
    nodeMissionAndValuesGoel(langcode: {eq: $langcode}){
          field_mission_title
          field_mission_subtitle
          field_mission_body {
            value
          }
          relationships {
            field_mission_image {
              uri {
                url
              }
              image_style_uri {
                _653_432
              }
            }
            field_vision_image {
              uri {
                url
              }
              image_style_uri {
                _653_432
              }
            }
            field_values_photo {
              image_style_uri {
                _653_432
              }
            }
            field_nextsteps_image {
              uri {
                url
              }
              image_style_uri {
                _653_432
              }
            }
            field_basics_beliefs_image {
              uri {
                url
              }
              image_style_uri {
                _653_432
              }
            }
          }
          field_vision_title
          field_vision_body {
            value
          }
          field_values_title
          field_values_subtitle
          field_values_body {
            value
          }
          field_basics_beliefs_title
          field_basics_beliefs_subtitle
          field_basics_beliefs_body {
            value
          }
          field_basics_beliefs_cta {
            title
            url
          }
    }
}
`

export default PageMissionandValues
